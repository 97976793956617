<template>
  <KycCommonHeader :title="$t('kyc.onboarding')" :subtitle="$t('kyc.institution')" @go-back="goBack" />
  <section class="tw-py-5 sm:tw-py-8 md:tw-py-10 lg:tw-py-14 tw-flex tw-flex-col md:tw-flex-row">
    <div class="tw-w-[228px] tw-pr-7 tw-hidden 2xl:tw-flex tw-flex-col tw-tracking-normal">
      <!-- <progress class="tw-progress tw-progress-primary tw-w-full tw-mb-4" :value="12.5 * current.index" max="100"></progress> -->
      <div class="tw-relative tw-mb-4 tw-h-5 tw-w-full">
        <progress
          class="tw-h-full tw-w-full tw-border tw-border-primary tw-rounded-full tw-progress tw-progress-primary tw-bg-secondary"
          :value="current.percentage"
          max="100"
        >
        </progress>
        <div class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-text-center tw-text-sm tw-z-20"> {{ current.percentage }}% </div>
      </div>
      <ul
        class="tw-flex tw-flex-col tw-gap-5 tw-text-sm tw-relative after:tw-content-['*'] after:tw-absolute after:tw-top-0 after:tw-left-3 after:tw-h-full after:tw-w-px after:tw-bg-[#ceced0] after:tw-z-0"
      >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 1 ? 'tw-font-bold tw-cursor-pointer ' : 'tw-font-medium'"
          @click="goPage('GeneralInfo')(1)"
        >
          <span :class="getStepClass(1)">1</span>
          {{ $t('kyc.general') }}</li
        >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 3 ? 'tw-font-bold tw-cursor-pointer ' : 'tw-font-medium'"
          @click="goPage('Documents')(3)"
        >
          <span :class="getStepClass(3)">2</span>{{ $t('kyc.documentation') }}</li
        >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 4 ? 'tw-font-bold tw-cursor-pointer' : 'tw-font-medium'"
          @click="goPage('BankingInfo')(4)"
        >
          <span :class="getStepClass(4)">3</span>{{ $t('kyc.banking_info') }}</li
        >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 5 ? 'tw-font-bold tw-cursor-pointer' : 'tw-font-medium'"
          @click="goPage('Questionnaire')(5)"
        >
          <span :class="getStepClass(5)">4</span>{{ $t('kyc.questionnaire') }}</li
        >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 6 ? 'tw-font-bold tw-cursor-pointer' : 'tw-font-medium'"
          @click="goPage('Representives')(6)"
        >
          <span :class="getStepClass(6)">5</span>{{ $t('kyc.representives') }}</li
        >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 7 ? 'tw-font-bold tw-cursor-pointer' : 'tw-font-medium'"
          @click="goPage('BeneficialOwners')(7)"
        >
          <span :class="getStepClass(7)">6</span>{{ $t('kyc.beneficial_owners') }}</li
        >
        <li
          class="tw-flex tw-gap-4"
          :class="current.index >= 8 ? 'tw-font-bold tw-cursor-pointer' : 'tw-font-medium'"
          @click="goPage('ParentCompanys')(8)"
        >
          <span :class="getStepClass(8)">7</span>{{ $t('kyc.parent_company') }}</li
        >
      </ul>
      <section>
        <h3 class="tw-text-xl tw-font-bold tw-leading-loose tw-py-6">{{ $t('kyc.faq') }}</h3>
        <div class="tw-flex tw-flex-col tw-gap-6">
          <div class="tw-flex tw-flex-col tw-gap-2">
            <h6 class="tw-text-sm tw-text-primary">
              {{ $t('kyc.institution_page.qa_list[0].qa') }}
            </h6>
            <i18n-t keypath="kyc.institution_page.qa_list[0].answer" class="tw-text-sm" tag="p">
              <template #here>
                <NuxtLinkLocale :to="`/kyc#continue`">
                  {{ $t('kyc.institution_page.here') }}
                </NuxtLinkLocale>
              </template>
            </i18n-t>
          </div>

          <div class="tw-flex tw-flex-col tw-gap-2">
            <h6 class="tw-text-sm tw-text-primary">
              {{ $t('kyc.institution_page.qa_list[1].qa') }}
            </h6>
            <p class="tw-text-sm">
              {{ $t('kyc.institution_page.qa_list[1].answer') }}
            </p>
          </div>

          <div class="tw-flex tw-flex-col tw-gap-2">
            <h6 class="tw-text-sm tw-text-primary">
              {{ $t('kyc.institution_page.qa_list[2].qa') }}
            </h6>
            <p class="tw-text-sm">
              {{ $t('kyc.institution_page.qa_list[2].answer') }}
            </p>
          </div>
        </div>
      </section>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-4">
      <div role="tablist" class="tw-tabs tw-tabs-bordered tw-tabs-lg 2xl:tw-hidden">
        <KycCommonStep
          class="arrow"
          :num="1"
          :currentIdx="current.index"
          :active="isCurrent('GeneralInfo')"
          :text="$t('kyc.general')"
          :goto="goPage('GeneralInfo')"
        />
        <KycCommonStep
          class="arrow"
          :num="3"
          :num2="2"
          :currentIdx="current.index"
          :active="isCurrent('Documents')"
          :text="$t('kyc.documentation')"
          :goto="goPage('Documents')"
        />
        <KycCommonStep
          class="arrow"
          :num="4"
          :num2="3"
          :currentIdx="current.index"
          :active="isCurrent('BankingInfo')"
          :text="$t('kyc.banking_info')"
          :goto="goPage('BankingInfo')"
        />
        <KycCommonStep
          class="arrow"
          :num="5"
          :num2="4"
          :currentIdx="current.index"
          :active="isCurrent('Questionnaire')"
          :text="$t('kyc.questionnaire')"
          :goto="goPage('Questionnaire')"
        />
        <KycCommonStep
          class="arrow"
          :num="6"
          :num2="5"
          :currentIdx="current.index"
          :active="current.index === 6"
          :text="$t('kyc.representives')"
          :goto="goPage('Representives')"
        />
        <KycCommonStep
          class="arrow"
          :num="7"
          :num2="6"
          :currentIdx="current.index"
          :active="current.index === 7"
          :text="$t('kyc.beneficial_owners')"
          :goto="goPage('BeneficialOwners')"
        />
        <KycCommonStep
          :num="8"
          :num2="7"
          :currentIdx="current.index"
          :active="isCurrent('ParentCompanys')"
          :text="$t('kyc.parent_company')"
          :goto="goPage('ParentCompanys')"
        />
      </div>
      <div class="tw-card tw-bg-secondary tw-rounded-2xl tw-py-14 tw-px-5 sm:tw-px-10 md:tw-px-20 lg:tw-px-20 tw-min-h-[400px]">
        <LazyKycInstitutionGIAddress v-if="isCurrent('GIAddress')" :goToNext="goToNext">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionGIAddress>
        <LazyKycInstitutionDocuments v-else-if="isCurrent('Documents')" :goToNext="goToNext">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionDocuments>
        <LazyKycInstitutionBankingInfo v-else-if="isCurrent('BankingInfo')" :goToNext="goToNext">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionBankingInfo>
        <LazyKycInstitutionQuestionnaire v-else-if="isCurrent('Questionnaire')" :goToNext="goToNext">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionQuestionnaire>
        <LazyKycInstitutionRepresentives
          v-else-if="isCurrent('Representives')"
          :goToEditPage="goToRepresentivePage"
          :goToNext="() => goTo('BeneficialOwners')"
        >
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionRepresentives>
        <LazyKycInstitutionRepresentive v-else-if="isCurrent('Representive')" :goToNext="() => goTo('Representives')" :current="current">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionRepresentive>

        <LazyKycInstitutionBeneficialOwners
          v-else-if="isCurrent('BeneficialOwners')"
          :goToEditPage="goToOwnerPage"
          :goToNext="() => goTo('ParentCompanys')"
        >
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionBeneficialOwners>
        <LazyKycInstitutionBeneficialOwner
          v-else-if="isCurrent('BeneficialOwner')"
          :goToNext="() => goTo('BeneficialOwners')"
          :current="current"
        >
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionBeneficialOwner>

        <LazyKycInstitutionParentCompanys v-else-if="isCurrent('ParentCompanys')" :goToNext="goToNext" :goToEditPage="goToParentPage">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionParentCompanys>
        <LazyKycInstitutionParentCompany v-else-if="isCurrent('ParentCompany')" :goToNext="() => goTo('ParentCompanys')" :current="current">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionParentCompany>

        <LazyKycInstitutionGeneralInfo v-else :goToNext="goToNext">
          <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
        </LazyKycInstitutionGeneralInfo>
      </div>
      <i18n-t keypath="kyc.continue_message" tag="p" class="tw-text-sm md:tw-text-base tw-text-center tw-relative tw-z-20">
        <template #here>
          <NuxtLinkLocale to="/kyc#continue" class="tw-text-primary">
            {{ $t('kyc.here') }}
          </NuxtLinkLocale>
        </template>
      </i18n-t>
    </div>
  </section>
</template>
<script setup lang="ts">
  const { goToNext, current, isCurrent, goTo, goToPrevious } = useStepper({
    GeneralInfo: {
      title: 'General',
      index: 1,
      percentage: 0,
    },
    GIAddress: {
      title: 'General',
      index: 2,
      percentage: 10,
    },
    Documents: {
      title: 'Documentation',
      index: 3,
      percentage: 20,
    },
    BankingInfo: {
      title: 'Banking Info',
      index: 4,
      percentage: 50,
    },
    Questionnaire: {
      title: 'Questionnaire',
      index: 5,
      percentage: 60,
    },
    Representives: {
      title: 'Representives',
      index: 6,
      percentage: 70,
    },
    Representive: {
      title: 'Representive',
      index: 6,
      percentage: 70,
      id: '',
    },
    BeneficialOwners: {
      title: 'Beneficial Owners',
      index: 7,
      percentage: 80,
    },
    BeneficialOwner: {
      title: 'Beneficial Owner',
      index: 7,
      percentage: 80,
    },
    ParentCompanys: {
      title: 'Parent Companys',
      index: 8,
      percentage: 90,
    },
    ParentCompany: {
      title: 'Parent Company',
      index: 8,
      percentage: 90,
    },
  })

  const router = useRouter()

  const getStepClass = (index: number) => {
    return {
      'tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-z-10': true,
      'tw-text-white tw-bg-success tw-border-success': current.value.index > index,
      'tw-text-white tw-bg-primary tw-border-primary': current.value.index == index,
      'tw-text-[#ceced0] tw-bg-white tw-border tw-border-[#ceced0]': current.value.index < index,
    }
  }

  const goBack = () => {
    if (isCurrent('GeneralInfo')) {
      router.back()
    } else if (isCurrent('ParentCompanys')) {
      goTo('BeneficialOwners')
    } else if (isCurrent('BeneficialOwners')) {
      goTo('Representives')
    } else {
      goToPrevious()
    }
  }
  
  const {
    public: { enableDevTools },
  } = useRuntimeConfig()

  const goPage = (pageName: string) => (i: number) => {
    if (!!enableDevTools) {
      goTo(pageName as any)
      return
    }
    if (current.value.index > i) {
      goTo(pageName as any)
    }
  }

  const goToRepresentivePage = (id?: string) => {
    goTo('Representive')
    if (isCurrent('Representive')) {
      ;(current.value as any).id = id ?? ''
    }
  }

  const goToOwnerPage = (id?: string) => {
    goTo('BeneficialOwner')
    if (isCurrent('BeneficialOwner')) {
      ;(current.value as any).id = id ?? ''
    }
  }

  const goToParentPage = (id?: string) => {
    goTo('ParentCompany')
    if (isCurrent('ParentCompany')) {
      ;(current.value as any).id = id ?? ''
    }
  }
</script>
<style></style>
