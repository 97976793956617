<template>
  <KycCommonHeader :title="$t('kyc.onboarding')" @go-back="$router.back()" />
  <section>
    <section class="tw-max-w-[960px] tw-mx-auto tw-mt-6">
      <p class="tw-text-base md:tw-text-lg">{{ $t('kyc_welcome[0]') }}</p>

      <p class="tw-text-base md:tw-text-lg tw-mt-5 md:tw-mt-7">{{ $t('kyc_welcome[1]') }}</p>
      <p class="tw-text-sm md:tw-text-base tw-mt-5 md:tw-mt-7" v-if="locale === 'zh_Hans'">
        注册用时：30分钟-1小时<br />
        账户审批：1天
      </p>
      <label class="tw-flex tw-space-x-2 tw-mt-5 md:tw-mt-7 tw-cursor-pointer">
        <input
          type="checkbox"
          v-model="agree"
          class="tw-checkbox tw-checkbox-ms tw-bg-white [--chkbg:theme(colors.white)] [--chkfg:theme(colors.success)]"
        />
        <i18n-t keypath="kyc_read_and_agree" tag="div" class="tw-text-sm md:tw-text-base">
          <template #terms>
            <a href="/terms" class="tw-text-primary" target="_blank">
              {{ $t('Terms of Use') }}
            </a>
          </template>
          <template #policy>
            <a href="/policy" class="tw-text-primary" target="_blank">
              {{ $t('Privacy Policy') }}
            </a>
          </template>
        </i18n-t>
      </label>
      <div class="tw-flex tw-justify-center tw-mt-8 sm:tw-mt-12 md:tw-mt-16 lg:tw-mt-20">
        <div>
          <UIButton data-welcome-entry-btn :disabled="!agree" class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl" @click="kycAgree()">
            {{ $t('Continue') }}
          </UIButton>
          <p class="tw-hidden md:tw-flex tw-justify-center tw-items-center tw-mt-4" v-if="agree">
            <i18n-t keypath="kyc_agree[1]" tag="span">
              <strong>Enter ↵</strong>
            </i18n-t>
          </p>
        </div>
      </div>
    </section>

    <div class="line tw-my-14"></div>

    <section class="tw-rounded-2xl md:tw-rounded-3xl tw-bg-[#f8f6f6] tw-py-12 tw-px-4 tw-text-center">
      <h4 class="tw-text-xl sm:tw-text-2xl md:tw-text-3xl lg:tw-text-4xl tw-font-black !tw-leading-normal">
        {{ $t('Complete an Existing Registration') }}
      </h4>
      <div class="tw-text-base md:tw-text-lg tw-mt-5">
        <p v-for="(item, index) in $tm('continue_messages')">
          {{ $rt(item) }}
        </p>
      </div>
      <div class="tw-max-w-[440px] tw-mx-auto tw-mt-10">
        <UITextInput
          :placeholder="$t('Enter your email')"
          v-model="email"
          @focus="onForce"
          :error-messages="[...v.email.$errors.map((e) => e.$message), ...[error?.message]].filter(Boolean)"
        >
        </UITextInput>
      </div>
      <div class="tw-flex tw-justify-center tw-mt-10">
        <UIButton
          class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl"
          :disabled="!getAuthToken() && v.$invalid"
          :loading="isPendingSave || isPending"
          @click="submit"
        >
          {{ $t('Submit') }}
        </UIButton>
      </div>
    </section>
  </section>
</template>
<script setup lang="ts">
  import { toast } from 'vue3-toastify'
  import { useVuelidate } from '@vuelidate/core'
  const { getSign, isPending, isSuccess } = useSign()
  const { getAuthToken } = useSession()
  const localeRoute = useLocaleRoute()
  const route = useRoute()
  const { locale } = useI18n()
  const agree = ref(false)
  const email = ref()

  const { required, email: _email } = useI18nlidate()

  const { common } = useApi()
  const { error, mutateAsync, isPending: isPendingSave, isSuccess: isSuccessSave } = common.useEmailMutation()

  const rules = computed(() => ({
    email: { required: required('Email'), email: _email },
  }))

  const v = useVuelidate(
    rules,
    {
      email,
    },
    { $autoDirty: true },
  )

  const { isSosoValue } = useSite()
  const kycAgree = () => {
    let state = '1'
    if (isSosoValue.value) {
      state = '11'
    }

    const _route = localeRoute({ query: { ...route.query, state } })
    if (_route) {
      return navigateTo(_route.fullPath)
    }
  }

  const handlePressEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && agree.value) {
      kycAgree()
    }
  }

  const submit = async () => {
    if (await v.value.$validate()) {
      const data = await mutateAsync(email.value)
      if (unref(isSuccessSave)) {
        toast.success(data.message)
      }
    }
  }

  const onForce = () => {
    if (!unref(isSuccess) && !unref(isPending)) {
      getSign()
    }
  }

  onMounted(() => {
    window.addEventListener('keyup', handlePressEnter)
  })
  onUnmounted(() => {
    window.removeEventListener('keyup', handlePressEnter)
  })
</script>
<style lang="scss" scoped>
  .tip {
    color: rgba(0, 0, 0, 0.45);
  }
  .line {
    height: 2px;
    border-top: 5px solid transparent;
    background-image: linear-gradient(90deg, theme('colors.primary') 0, theme('colors.primary') 50%, #fff 0, #fff 50%);
    background-size: 1rem 2px;
    background-repeat: repeat-x;
    border: 0 none;
  }
</style>
