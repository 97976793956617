<template>
  <section class="tw-flex-1 tw-flex tw-flex-col">
    <CommonHeader />
    <ClientOnly>
      <KycDev v-if="enableDevTools" />
      <Kyc v-else />
    </ClientOnly>
  </section>
</template>
<script setup lang="ts">
  const {
    public: { enableDevTools },
  } = useRuntimeConfig()


  useHead({
    script: [
      {
        src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDh8SNezsOi0aIcq9qLvjHz7zWMP3zcmQg&libraries=places',
        async: true,
      },
    ],
  })
  definePageMeta({
    middleware: ['ip2location'],
  })
</script>
