const convertLottieColorToRgb = (lottieColor: number[]) => {
  return [Math.round(lottieColor[0] * 255), Math.round(lottieColor[1] * 255), Math.round(lottieColor[2] * 255)]
}

const convertLottieColorToRgba = (lottieColor: number[]) => {
  return [Math.round(lottieColor[0] * 255), Math.round(lottieColor[1] * 255), Math.round(lottieColor[2] * 255), lottieColor[3]]
}

const convertColorToLottieColor = (color: string | number[] | undefined) => {
  if (typeof color === 'string' && color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    if (!result) {
      throw new Error('Color can be only hex or rgb array (ex. [10,20,30])')
    }
    return [
      Math.round((parseInt(result[1], 16) / 255) * 1000) / 1000,
      Math.round((parseInt(result[2], 16) / 255) * 1000) / 1000,
      Math.round((parseInt(result[3], 16) / 255) * 1000) / 1000,
    ]
  } else if (typeof color === 'object' && color.length === 3 && color.every((item) => item >= 0 && item <= 255)) {
    return [Math.round((color[0] / 255) * 1000) / 1000, Math.round((color[1] / 255) * 1000) / 1000, Math.round((color[2] / 255) * 1000) / 1000]
  } else if (!color) {
    return undefined
  } else {
    throw new Error('Color can be only hex or rgb array (ex. [10,20,30])')
  }
}

export const getColors = (lottieObj: any): any => {
  const res: any = []
  function doGet(obj: any) {
    if (obj.s && Array.isArray(obj.s) && obj.s.length === 4) {
      res.push(convertLottieColorToRgba(obj.s))
    } else if (obj.c && obj.c.k) {
      if (Array.isArray(obj.c.k) && typeof obj.c.k[0] !== 'number') {
        doGet(obj.c.k)
      } else {
        res.push(convertLottieColorToRgb(obj.c.k))
      }
    } else {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          doGet(obj[key])
        }
      }
    }

    return res
  }
  doGet(lottieObj)
  return res
}

export const replaceColor = (sourceColor: number[], targetColor: string | number[], lottieObj: any): any => {
  const sourceLottieColor = convertColorToLottieColor(sourceColor)
  const genTargetLottieColor = convertColorToLottieColor(targetColor)

  function doGet(obj: any) {
    if (obj.s && Array.isArray(obj.s) && obj.s.length === 4) {
      const _b = convertLottieColorToRgba(obj.s)
      if (_b.join(',') === sourceLottieColor?.join(',')) {
        obj.c.k = [...sourceLottieColor, 1]
      }
    } else if (obj.c && obj.c.k) {
      if (Array.isArray(obj.c.k) && typeof obj.c.k[0] !== 'number') {
        doGet(obj.c.k)
      } else {
        const _a = convertLottieColorToRgb(obj.c.k)
        if (_a.join(',') === sourceColor?.join(',')) {
          obj.c.k = genTargetLottieColor
        }
      }
    } else {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          doGet(obj[key])
        }
      }
    }
    return obj
  }
  return doGet(lottieObj)
}
